<script setup lang="ts">
const route = useRoute();
</script>

<template>
  <div
    :class="[
      'flex min-h-screen flex-col transition duration-100 ease-in-out',
      route.meta?.backgroundClass || 'bg-white dark:bg-black',
    ]"
  >
    <div class="w-full flex-grow">
      <slot />
    </div>
  </div>
</template>
